import ConvivaSDK from '@convivainc/conviva-js-coresdk';
import { ConvivaService } from './conviva';
import { toDeviceCategory, toPlayerName } from './utils/platformSpecific';
class FlutterWebConvivaService extends ConvivaService {
    constructor(platform: string) {
        const deviceData = {
            category: toDeviceCategory(platform),
        };
        const playerName = toPlayerName(platform);

        super(ConvivaSDK, deviceData, playerName);

        window.__flutter_web_conviva_service = this;
    }
}

declare global {
    interface Window {
        __flutter_web_conviva_service: FlutterWebConvivaService,
        WebConvivaServiceNamespace: {
            FlutterWebConvivaService: { new(platform: string): FlutterWebConvivaService },
        };
        webAdobeAnalyticsNameSpace: {
            FlutterAdobeAnalytics: { new(): import('./adobe/index').default };
        }
    }
}

window.WebConvivaServiceNamespace = {
    FlutterWebConvivaService
};

if (shouldInitializeAdobeAnalytics()) {
    import('./adobe/index').then(module => {
        const FlutterAdobeAnalytics = module.default;

        window.webAdobeAnalyticsNameSpace = {
            FlutterAdobeAnalytics,
        };
    }).catch(e => {
        console.error('Error initializing Adobe Analytics:', e);
    });
}

function shouldInitializeAdobeAnalytics(): boolean {
    const userAgent = navigator.userAgent;
    const chromiumVersion = getChromiumVersion(userAgent);
    const tizenVersion = getTizenVersion(userAgent);
    const isWebOS = isWebOSPlatform(userAgent);

    if (tizenVersion !== null && tizenVersion < 6) {
        return false;
    }

    if (isWebOS && chromiumVersion !== null && chromiumVersion < 89) {
        return false;
    }

    return true;
}

function getChromiumVersion(userAgent: string): number | null {
    const chromiumRegex = /Chrom(e|ium)\/(\d+)\./;
    const chromiumMatch = chromiumRegex.exec(userAgent);

    return chromiumMatch && chromiumMatch[2] ? parseInt(chromiumMatch[2], 10) : null;
}

function getTizenVersion(userAgent: string): number | null {
    const tizenRegex = /Tizen\s(\d+\.\d+)/;
    const tizenMatch = tizenRegex.exec(userAgent);

    if (tizenMatch && tizenMatch[1]) {

        const versionParts = tizenMatch[1].split('.');

        if (versionParts.length >= 2) {
            const major = parseInt(versionParts[0] || '0', 10);
            const minor = parseInt(versionParts[1] || '0', 10);


            if (!isNaN(major) && !isNaN(minor)) {
                return parseFloat(`${major}.${minor}`);
            }
        }
    }

    return null;
}

function isWebOSPlatform(userAgent: string): boolean {
    const webOSRegex = /Web0?S/i;

    return webOSRegex.exec(userAgent) !== null;
}

